.icon-segment {
  cursor: pointer;
  text-align: center;
  padding: 5px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  margin: 2px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 40px !important;
  width: 40px !important;
}

.icon-segment.selected {
  background-color: #e0e0e0 !important;
  border-color: #aaa !important;
}

.icon-segment:hover {
  background-color: #f0f0f0 !important;
}

.icon-dropdown .icon-grid {
  padding: 10px;
}

.icon-dropdown .menu {
  width: auto !important;
  min-width: 200px !important;
  max-height: 300px !important;
  overflow-y: auto;
}
