@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #113b60; /* Your custom primary color */
  --primary-hover: #081c2e; /* Your custom primary color */
  --secondary: #d95558; /* Your custom secondary color */
  --secondary-hover: #c8282a; /* Your custom secondary color */
  --selected-font: "default-font"; /* Default font */
  --custom-font-family: "default-font"; /* New variable for custom font */
}

/* Override Semantic UI's primary and secondary colors */
.ui.primary.button,
.ui.primary.buttons .button,
.ui.primary.buttons .or,
.ui.primary.labeled.button .label,
.ui.primary.labeled.button .icon,
.ui.primary.label {
  background-color: var(--primary) !important;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover,
.ui.primary.buttons .or:hover,
.ui.primary.labeled.button:hover .label,
.ui.primary.labeled.button:hover .icon,
.ui.primary.label:hover {
  background-color: var(--primary-hover) !important;
}

.ui.secondary.button,
.ui.secondary.buttons .button,
.ui.secondary.buttons .or,
.ui.secondary.labeled.button .label,
.ui.secondary.labeled.button .icon,
.ui.secondary.label {
  background-color: var(--secondary) !important;
}

.ui.secondary.button:hover,
.ui.secondary.buttons .button:hover,
.ui.secondary.buttons .or:hover,
.ui.secondary.labeled.button:hover .label,
.ui.secondary.labeled.button:hover .icon,
.ui.secondary.label:hover {
  background-color: var(--secondary-hover) !important;
}

body {
  margin: 0;
  font-family: var(--custom-font-family, "default-font");
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

p {
  opacity: 90%;
}
